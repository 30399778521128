import { Box, Typography } from "@mui/material";
import ProductCard from "./ProductCard";
import { useLoaderData } from "@remix-run/react";
import type { loader } from "~/routes/_index";

export default function HomeProductSelection() {
  const data = useLoaderData<typeof loader>();
  const productLists = data.home.productSections;

  return productLists.map((pl, index) => (
    <div key={`list-${index}`}>
      <Typography
        variant="h4"
        sx={{ marginBottom: "1em", marginTop: "1em", textAlign: { xs: "center", md: "inherit" } }}
      >
        {pl.sectionName}
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: (t) => t.spacing(4), rowGap: (t) => t.spacing(4) }}>
      {pl.products.map((p, pIndex) => (
        <ProductCard key={pIndex} product={p} />
      ))}
      </Box>
    </div>
  ));
}
